/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Swal from 'sweetalert2'
import globalMixin from './mixins/globalMixin'
import VueForm from 'vue-form';
import options from './validations/validations'
import 'primeicons/primeicons.css';
//import jquery from 'jquery'
//se importan los estilos de la plantilla adminLTE
window.$ = window.jQuery = require('jquery')
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import PickList from 'primevue/picklist';
import InputMask from 'primevue/inputmask';
import InputSwitch from 'primevue/inputswitch';
import TreeVue from 'primevue/tree';
import AccordionVue from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import CheckBox from 'primevue/checkbox';
import CardVue from 'primevue/card';
import FileUpload from 'primevue/fileupload';
import OverlayPanel from 'primevue/overlaypanel';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Steps from 'primevue/steps';
import ToggleButton from 'primevue/togglebutton';
import Card from 'primevue/card';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import MultiSelect from 'primevue/multiselect';
import Chart from 'primevue/chart';
import OrderList from 'primevue/orderlist';



import 'primevue/resources/themes/lara-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
//import 'primeicons/primeicons.css'

Vue.use(PrimeVue);
Vue.component('Dialog', Dialog);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('InputText', InputText);
Vue.component('InputNumber', InputNumber);
Vue.component('AutoComplete', AutoComplete);
Vue.component('PickList', PickList);
Vue.component('InputMask', InputMask);
Vue.component('InputSwitch', InputSwitch);
Vue.component('TreeVue', TreeVue);
Vue.component('AccordionVue', AccordionVue);
Vue.component('AccordionTab', AccordionTab);
Vue.component('CheckBox', CheckBox);
Vue.component('CardVue', CardVue);
Vue.component('FileUpload', FileUpload);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('Dropdown', Dropdown);
Vue.component('Button', Button);
Vue.component('Toast', Toast);
Vue.component('Steps', Steps);
Vue.component('ToggleButton', ToggleButton);
Vue.component('Card', Card);
Vue.component('Panel', Panel);
Vue.component('Textarea', Textarea);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Chart', Chart);
Vue.component('OrderList', OrderList);




Vue.directive('tooltip', Tooltip);





Vue.config.productionTip = false

Vue.mixin(globalMixin)


//Validaciones
Vue.use(VueForm);
Vue.use(VueForm, options);
Vue.use(ToastService);

Vue.component('vueDropzone', vue2Dropzone)

window.axios = axios
//window.apiURL = 'https://localhost:44317/'
window.apiURL = 'https://api-innovatiq2-0.azurewebsites.net/'
window.router = router
window.Swal = Swal
window.Fire = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
