//import axios from "axios"

/* eslint-disable */
const globalMixin = {
    data() {
        return {

        }
    },
    methods: {
        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },
        changeRol() {
            if (this.idSelectedRol == 4) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 4
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/TeacherHome')
            }
            else if (this.idSelectedRol == 5) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 5
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/StudentHome')
            }
            else if (this.idSelectedRol == 1) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 1
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/AdminHome')
            }
            else if (this.idSelectedRol == 2) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 2
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/AdminHome')
            }
            else if (this.idSelectedRol == 3) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 3
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/AdminHome')
            }
            else if (this.idSelectedRol == 6) {
                let lclStore = JSON.parse(localStorage.dataPerson)
                lclStore.idSelectedRol = 6
                localStorage.setItem("dataPerson", JSON.stringify(lclStore))

                router.push('/SupervisorHome')
            }
        },
        logout() {
            let isRCSA = localStorage.isRCSA
            console.log('logout: ' + isRCSA)
            window.localStorage.clear()
            if (isRCSA == 1) {
                router.push('/rcsa')
            }
            else {
                router.push('/')
            }

        },
        showSuccess(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'success', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showInfo(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'info', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showWarn(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'warn', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        showError(sInfoMessage, sMessage) {
            this.$toast.add({ severity: 'error', summary: sInfoMessage, detail: sMessage, life: 3000 });
        },
        formatDateTime(value) {
            if (value == null)
                return ''
            const date = new Date(value.toString());
            return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear() + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
        },
        formatDate(dDate) {
            if (dDate == null)
                return ''

            var tuple = String(dDate).split("T");
            var date = tuple[0];
            var dateTuple = date.split("-");
            var day = dateTuple[2];
            var month = dateTuple[1];
            var year = dateTuple[0];
            var newFormatedDate = [day, month, year].join("/");
            console.log(newFormatedDate)
            return newFormatedDate;
        },
        async loadTinyMCE(sModule, id) {
            let apiRequest = ""
            console.log(sModule);
            if(sModule === 'AdminContent¬¬'){
                apiRequest = apiURL + 'Content/uploadImage?idCourse=' + id
            }
            else{
                apiRequest = apiURL + 'TeacherQuiz/uploadImage?sModule=' + sModule + '&id=' + id;
            }

            const plugin = document.createElement("script");
            plugin.setAttribute(
                "src",
                "/assets/js/tinymce/tinymce.min.js"
            );
            plugin.setAttribute(
                "referrerpolicy",
                "origin"
            );
            //plugin.async = true;
            document.head.appendChild(plugin);

            setTimeout(() => {
                tinymce.init({
                    license_key: 'gpl',
                    selector: 'textarea#open-source-plugins',
                    plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                    toolbar: 'undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl',
                    toolbar_sticky: true,
                    toolbar_mode: 'wrap',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    images_upload_url: apiRequest,
                    automatic_uploads: true,
                    //width: 900,
                    mergetags_list: [
                        { value: 'First.Name', title: 'First Name' },
                        { value: 'Email', title: 'Email' },
                    ],
                    setup(editor) {
                        editor.on("keydown", function (e) {
                            if ((e.keyCode == 8 || e.keyCode == 46) && tinymce.activeEditor.selection) {
                                var selectedNode = tinymce.activeEditor.selection.getNode();
                                if (selectedNode && selectedNode.nodeName == 'IMG') {
                                    var imageSrc = selectedNode.src;
                                    console.log('Imagen Seleccionada: ' + imageSrc)
                                    //here you can call your server to delete the image
                                }

                            }
                        });
                    }



                });
            }, "500"); 
        }
    }
}

export default globalMixin